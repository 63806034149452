import axios from 'axios'
import apiWrapper from './apiWrapper'
import { API_URL } from '../utils/constants'

const signOut = async () => {
  await apiWrapper()

  return axios.post(`${API_URL}/api/auth/signout`)
}
export default signOut
