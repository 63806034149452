import axios from 'axios'
import apiWrapper from './apiWrapper'

import { API_URL } from '../utils/constants'

const auth = async (mtoken: string) => {
  await apiWrapper()

  return axios.post(`${API_URL}/api/auth/magic-signin`, { mtoken })
}
export default auth
